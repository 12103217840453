import { TutorialParent } from "../TutorialParent";
import { Translate } from "components/common/Translate/Translate";
import { ImageResizerTutorialIcon } from "components/common/Icons/ImageResizerTutorialIcon";

export const ImageResizerTutorial = () => {
    return (
        <TutorialParent>
            <div className="flex flex-col gap-7">
                {/* header */}
                <h2 className="font-semibold whitespace-nowrap sub-heading mb-3">
                    <Translate keyName="tutorial-title" />
                </h2>
                <ul className="flex flex-col gap-2">
                    {/* tutorial points */}
                    <li className="">
                        <Translate keyName="tutorial-1" />
                    </li>
                    <li className="">
                        <Translate keyName="tutorial-2" />
                    </li>
                    <li className="">
                        <Translate keyName="tutorial-3" />
                    </li>
                </ul>
            </div>
            {/* icon */}
            <div className="bg-slate-50 dark:bg-darkSurface-200 px-12 py-28">
                <ImageResizerTutorialIcon />
            </div>
        </TutorialParent>
    );
};
