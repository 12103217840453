import type { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ImageResizerContent } from "components/modules/Content/ImageResizerContent/ImageResizerContent";
import { ImageResizerTutorial } from "components/modules/Tutorial/ImageResizerTutorial/ImageResizerTutorial";
import { UploadPageLayout } from "components/modules/Layout/UploadPageLayout/UploadPageLayout";
import { Navbar } from "components/modules/Navigation/Navbar/Navbar";
import Head from "next/head";
import { LanguageProvider } from "client/contexts/LanguageProvider";
import { commonLanguageFiles } from "client/config";
import { Translate } from "components/common/Translate/Translate";
import { translateFunc } from "client/utils/translateFunc";
import dynamic from "next/dynamic";

const ResizeUploader = dynamic<{}>(() =>
    import("components/modules/Uploader/Resize/ResizeUploader").then((mod) => mod.ResizeUploader),
);

const Home = () => {
    const title = translateFunc("page-title");
    const description = translateFunc("meta-description");

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="/social.png" />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
            </Head>
            <UploadPageLayout
                navbar={<Navbar url="/" />}
                title={<Translate keyName="title" />}
                subTitle={<Translate keyName="sub-title" />}
                tutorial={<ImageResizerTutorial />}
                uploader={<ResizeUploader />}
                content={<ImageResizerContent />}
                pageUrl="/"
            />
        </>
    );
};

const LanguageWrapper = () => {
    return (
        <LanguageProvider files={languageFiles}>
            <Home />
        </LanguageProvider>
    );
};

const languageFiles = ["single-resizer-landing", "uploader", ...commonLanguageFiles];

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
    props: {
        ...(await serverSideTranslations(locale ?? "", languageFiles)),
    },
});

export default LanguageWrapper;
